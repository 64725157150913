import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Components/Login';
import Userdashboard from './Components/Userdashboard';
import Admindashboard from './Components/Admindashboard';
import AddCustomers from './Components/AddCustomers';
import AddOrderScreen from './Components/AddOrderScreen';
import Order from './Components/Order';
import CustomerList from './Components/CustomerList';
import OrderList from './Components/OrderList';
import FullView from './Components/FullView';
import ShowOrders from './Components/ShowOrders';
function App() {
  return (
    <Router>
      <div>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Userdashboard" element={<Userdashboard/>} />
        <Route path="/Admindashboard" element={<Admindashboard/>} />
        <Route path="/AddCustomers" element={<AddCustomers/>} />
        <Route path="/AddOrderScreen" element={<AddOrderScreen/>} />
        <Route path="/Order/:customerId" element={<Order />} />
        <Route path="/CustomerList" element={<CustomerList/>}/>
        <Route path="/OrderList/:customerId" element={<OrderList />} />
        <Route path="/FullView/:id" element={<FullView />} />
        <Route path="/ShowOrders" element={<ShowOrders />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
