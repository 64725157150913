import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Make a POST request to your PHP login endpoint
      const response = await fetch('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      // Parse the JSON response
      const data = await response.json();

      // Check the status received from the backend
      if (data.status === 'a') {
        // If status is 'a', navigate to admin dashboard
        navigate('/Admindashboard');
      } else if (data.status === 'u') {
        // If status is 'u', navigate to user dashboard
        navigate('/ShowOrders');
      } else {
        // If status is neither 'a' nor 'u', display an error message
        alert('Invalid username or password');
      }

      // Store user ID in session storage
      sessionStorage.setItem('userId', data.userId);

      // Log the user ID to console
      console.log('User ID:', data.userId);
    } catch (error) {
      // Handle any errors that occur during the fetch request
      console.error('Error:', error);
      alert('An error occurred while logging in');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="text-center">
            <h2>Login</h2>
            <form>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleLogin}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
