import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar1 = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    // Implement logout logic here
    sessionStorage.removeItem('userId');
    // Redirect to login or any other desired page after logout
    navigate('/');
  };

  const goToPage = (page) => {
    navigate(`/${page}`);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <span className="navbar-brand" onClick={() => navigate('/Admindashboard')}>
          HarisOrderUpdates
        </span>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <span className="nav-link mx-2" onClick={() => goToPage('AddCustomers')}>Add Customers</span>
            </li>
            {/* <li className="nav-item">
              <span className="nav-link mx-2" onClick={() => goToPage('EditUserScreen')}>Edit Admins</span>
            </li> */}
            <li className="nav-item">
              <span className="nav-link mx-2" onClick={() => goToPage('AddOrderScreen')}>Add Orders</span>
            </li>
            <li className="nav-item">
              <span className="nav-link mx-2" onClick={() => goToPage('CustomerList')}>Manage Orders</span>
            </li>
          </ul>
        </div>

        <div className="d-flex">
          <button className="btn btn-danger" onClick={handleLogoutClick}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar1;
