import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar1 from './Navbar1';

const OrderList = () => {
  const { customerId } = useParams();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders(customerId);
  }, [customerId]);

  const fetchOrders = async (customerId) => {
    try {
      const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/getorders.php?customerId=${customerId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      alert('Error fetching orders');
    }
  };

  const handleDelete = async (orderId) => {
    // Display a confirmation dialog
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    
    // If user confirms deletion
    if (confirmDelete) {
      try {
        const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/deleteorder.php?orderId=${orderId}`, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          // If the delete is successful, fetch the updated list of orders
          fetchOrders(customerId);
        } else {
          console.error('Error deleting order:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting order:', error);
      }
    }
  };
  

  return (
    <div>
      <Navbar1 />
      <h2>Orders for Customer ID: {customerId}</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Clubname</th>
            <th>Order Quantity</th>
            <th>Date</th>
            <th>Progress</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>HOU-{order.order_id}</td>
              <td>{order.clubname}</td>
              <td>{order.order_quantity}</td>
              <td>{order.date}</td>
              <td>{order.progress}</td>
              <td>
                <Link to={`/FullView/${order.id}`} className="btn btn-primary">Full View</Link>
                {order.progress === 'Dispatch' && (
                  <button onClick={() => handleDelete(order.id)} className="btn btn-danger ms-2">Delete</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
