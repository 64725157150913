import React from 'react';

function Userdashboard({  }) {
  return (
    <div>
      <h2>Welcome to our Order Tracking App</h2>
      <p>This is the home page of our app.</p>
      
    </div>
  );
}

export default Userdashboard;
