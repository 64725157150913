import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar1 from './Navbar1';
import './FullView.css'; // Import CSS file for styling

const FullView = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProgress, setSelectedProgress] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Options array for progress stages
  const options = [
    'Designing',
    'Approval',
    'Printing',
    'Fabric Transfer',
    'Cutting',
    'Stitching',
    'Threading',
    'Pressing',
    'Packing',
    'Dispatch'
  ];

  useEffect(() => {
    fetchOrder(id);
  }, [id]);

  const fetchOrder = async (orderId) => {
    try {
      const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/getorder.php?orderId=${orderId}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch order details');
      }
      const data = await response.json();
      setOrder(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order details:', error);
      alert('Error fetching order details');
    }
  };

  const handleSaveProgress = async () => {
    try {
      const response = await fetch('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/updateprogress.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderId: id,
          progress: selectedProgress
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to save progress');
      }
      // Reload order details after updating progress
      fetchOrder(id);
      setShowModal(false); // Close the modal after saving progress
    } catch (error) {
      console.error('Error saving progress:', error);
      alert('Error Saving Progress');
    }
  };

  const isCompleted = (option) => {
    const currentIndex = options.indexOf(order.progress);
    const optionIndex = options.indexOf(option);
    return optionIndex <= currentIndex;
  };

  return (
    <div>
      <Navbar1/>
      <h2>Full View for Order ID: HOU-{id}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        order ? (
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td><strong>Order ID </strong></td>
                  <td>HOU-{order.order_id}</td>
                </tr>
                <tr>
                  <td><strong>Customer ID</strong></td>
                  <td>{order.customer_id}</td>
                </tr>
                <tr>
                  <td><strong>Clubname</strong></td>
                  <td>{order.clubname}</td>
                </tr>
                <tr>
                  <td><strong>Order Quantity</strong></td>
                  <td>{order.order_quantity}</td>
                </tr>
                <tr>
                  <td><strong>Date</strong></td>
                  <td>{order.date}</td>
                </tr>
                <tr>
                  <td><strong>Order Progress</strong></td>
                  <td>
                    {order.progress}
                    <button onClick={() => setShowModal(true)} className="btn btn-primary mx-2">Change Progress</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p>No order found for ID: {id}</p>
        )
      )}
      {/* Change Progress Modal */}
      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Progress</h5>
                
              </div>
              <div className="modal-body">
                <select value={selectedProgress} onChange={(e) => setSelectedProgress(e.target.value)} className="form-control">
                  <option value="">Select Progress</option>
                  {options.map((option) => (
                    <option key={option} value={option} className={isCompleted(option) ? 'completed' : ''}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveProgress}>Save</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullView;
