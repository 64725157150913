import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar1 from './Navbar1';

const AddCustomer = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    name: '',
  });

  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleAddUser = async (event) => {
    event.preventDefault();

    try {
      const userDataWithStatus = {
        ...userData,
        status: 'a',
      };

      const response = await axios.post('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/adduser.php', userDataWithStatus, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        console.log('User added successfully!');
        alert('User added successfully!');
        
        setUserData({
          username: '',
          password: '',
          name: '',
        });
      } else {
        // setError(response.data.message || 'Error adding user. Please try again.');

        alert('Error adding user. Please try again.');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      // setError('Error adding user. Please try again.');
      alert('Error adding user');
    }
  };

  const handleAlertClose = () => {
    setError(null);
  };
  

  return (
    <div><Navbar1/>
    <div className="container mt-5">
      <h1 className="text-center">Add User</h1>
      {error && (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Error:</strong> {error}
          <button type="button" className="btn-close" aria-label="Close" onClick={handleAlertClose}></button>
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-md-6">
          <form onSubmit={handleAddUser}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={userData.username}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={userData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Add User
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AddCustomer;
