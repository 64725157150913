import React, { useState } from 'react';
import Navbar from './Navbar1';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const Order = () => {
    const { customerId } = useParams();
    const [clubname, setClubname] = useState('');
    const [orderQuantity, setOrderQuantity] = useState('');
    const [date, setDate] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/addorder.php', {
                clubname,
                orderQuantity,
                date,
                customer_id: customerId // Include customer_id in the request body
            });
    
            console.log(response.data);
            alert('Order Added Successfully.');
            // Optionally, you can redirect the user or display a success message here
        } catch (error) {
            console.error('Error adding order:', error);
            alert('Error adding order');
            // Handle error here
        }
    };
    
    

    return (
        <div>
            <Navbar />
            <div className="container mt-5">
                <h2 className="text-center mb-4">Add Order For: {customerId}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="clubname" className="form-label">Club Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="clubname"
                            value={clubname}
                            onChange={(e) => setClubname(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="orderQuantity" className="form-label">Order Quantity:</label>
                        <input
                            type="number"
                            className="form-control"
                            id="orderQuantity"
                            value={orderQuantity}
                            onChange={(e) => setOrderQuantity(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            id="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Add Order</button>
                </form>
            </div>
        </div>
    );
};

export default Order;
