import React, { useState, useEffect } from 'react';
import Navbar1 from './Navbar1';

function Admindashboard() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  // Fetch the list of users from the backend when the component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Make a GET request to your backend endpoint that retrieves the list of users
      const response = await fetch('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/getusers.php');
      const data = await response.json();
      setUsers(data); // Update the users state with the fetched data
    } catch (error) {
      console.error('Error fetching users:', error);
      alert('Error fetching users');
    }
  };

  const handleEdit = (id) => {
    // Set the editingUser state to the ID of the user to be edited
    setEditingUser(id);
  };

  const handleSave = async (id, updatedUserData) => {
    try {
      // Make a POST request to your backend endpoint to update the user data
      const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/edituser.php?id=${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUserData),
      });

      if (response.ok) {
        // If the update is successful, fetch the updated list of users
        fetchUsers();
        setEditingUser(null); // Reset editing state
        alert('User Information Updated Successfully.');
      } else {
        console.error('Error updating user:', response.statusText);
        alert('Error updating User');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating User');
    }
  };

  const handleCancel = () => {
    // Reset editing state
    setEditingUser(null);
  };

  const handleDelete = async (id) => {
    try {
      // Check if the user ID is 1, and prevent deletion
      if (id === 1) {
        alert('Cannot delete this user');
        return;
      }
  
      // Ask for confirmation before deleting the user
      const confirmDelete = window.confirm('Are you sure you want to delete this user?');
  
      if (!confirmDelete) {
        return;
      }
  
      // Make a POST request to your backend endpoint to delete the user
      const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/deleteuser.php?id=${id}`, {
        method: 'POST',
      });
  
      if (response.ok) {
        // If the delete is successful, fetch the updated list of users
        fetchUsers();
      } else {
        console.error('Error deleting user:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  

  const handleInputChange = (id, field, value) => {
    // Update the user data in the state with the new value
    setUsers(users.map(user => {
      if (user.id === id) {
        return { ...user, [field]: value };
      }
      return user;
    }));
  };

  return (
    <div>
      <Navbar1 />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Welcome to Order Tracking App</h2>
        <h1>List of Customers:</h1>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Name</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    {editingUser === user.id ? (
                      <input
                        type="text"
                        value={user.username}
                        onChange={(e) => handleInputChange(user.id, 'username', e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      user.username
                    )}
                  </td>
                  <td>
                    {editingUser === user.id ? (
                      <input
                        type="text"
                        value={user.name}
                        onChange={(e) => handleInputChange(user.id, 'name', e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      user.name
                    )}
                  </td>
                  <td>
                    {editingUser === user.id ? (
                      <input
                        type="password"
                        value={user.password}
                        onChange={(e) => handleInputChange(user.id, 'password', e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      user.password
                    )}
                  </td>
                  <td>
                    {editingUser === user.id ? (
                      <>
                        <button className="btn btn-success btn-sm" onClick={() => handleSave(user.id, user)}>Save</button>
                        <button className="btn btn-danger btn-sm ms-1" onClick={handleCancel}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(user.id)}>Edit</button>
                        {user.id !== 1 && (
                          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(user.id)}>Delete</button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Admindashboard;
