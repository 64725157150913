import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar1';

const AddOrderScreen = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await fetch('https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/getusers.php');
      if (!response.ok) {
        throw new Error('Failed to fetch customers');
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      alert('Error fetching customers');
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
  };

  const handleProceedToAddOrder = () => {
    // Navigate to the Order.js page with the selected customer's ID as a URL parameter
    navigate(`/Order/${selectedCustomer.id}`);
  };

  return (
    <div>
      <Navbar />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Choose A customer To Add Order</h2>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                  <tr
                    key={customer.id}
                    onClick={() => {
                      if (customer.id !== userId) {
                        handleCustomerSelect(customer);
                      }
                    }}
                    className={customer.id === userId ? 'disabled' : ''}
                  >
                    <td>{customer.id}</td>
                    <td>{customer.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {selectedCustomer && (
          <div className="row mt-4">
            <div className="col-md-6 offset-md-3">
              <div className="text-center">
                <h4>Selected Customer:</h4>
                <p>{selectedCustomer.name}</p>
                <button
                  className="btn btn-primary"
                  onClick={handleProceedToAddOrder}
                  disabled={selectedCustomer.id === userId}
                >
                  Proceed to Add Order
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOrderScreen;
