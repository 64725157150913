import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar2 from './Navbar2';

const ShowOrders = () => {
  // Retrieve customerId from session storage
  const customerId = sessionStorage.getItem('userId');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (customerId) {
      fetchOrders(customerId);
    }
  }, [customerId]);

  const fetchOrders = async (customerId) => {
    try {
      const response = await fetch(`https://harisorderupdates.epsoldevops.com/HarisOrderUpdates/getorders.php?customerId=${customerId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      alert('Error fetching orders');
      
    }
  };

  return (
    <div>
      <Navbar2/>
      <h2>Orders for Customer ID: {customerId}</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Clubname</th>
            <th>Order Quantity</th>
            <th>Date</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>HOU-{order.order_id}</td>
              <td>{order.clubname}</td>
              <td>{order.order_quantity}</td>
              <td>{order.date}</td>
              <td>{order.progress}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShowOrders;
