import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar2 = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    // Implement logout logic here
    sessionStorage.removeItem('userId');
    // Redirect to login or any other desired page after logout
    navigate('/');
  };

  const goToPage = (page) => {
    navigate(`/${page}`);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <span className="navbar-brand" onClick={() => navigate('/Admindashboard')}>
          HarisOrderUpdates
        </span>

        
        <div className="d-flex">
          <button className="btn btn-danger" onClick={handleLogoutClick}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar2;
